import React, { useReducer } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import PagesList from "../../../../components/pages-list/PagesList";
import Recipient from "../../../../components/awards/recipient";
import Appreciation from "../../../../components/awards/appreciation";
import Other from "../../../../components/awards/other";
import Card from "../../../../components/awards/awards-card";

// Array of card data
const cardData = [
  {
    title: "Recipient",
    description:
      "Who would you like to appreciate? Enter their name or select from the list of colleagues.",
    type: "Recipient",
  },
  {
    title: "Appreciation For",
    description:
      "What's the reason for appreciation? Mention specific achievement or performance.",
    type: "Appreciation",
  },
  {
    title: "Other Details",
    description: "Provide other details for the award.",
    type: "Other",
  },
];

const PerformanceAwards = () => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "Recipient":
        return { page: "Recipient" };
      case "Appreciation":
        return { page: "Appreciation" };
      case "Other":
        return { page: "Other" };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, { page: "Recipient" });

  const renderPage = () => {
    switch (state.page) {
      case "Recipient":
        return <Recipient />;
      case "Appreciation":
        return <Appreciation />;
      case "Other":
        return <Other />;
      default:
        return <Recipient />;
    }
  };

  return (
    <div className="bg-[#F5F7F8] min-h-screen">
      <Navbar />
      <PagesList />

      <div className="flex items-center gap-4 px-4 sm:px-6 lg:px-12 pt-4">
        <p className="font-Kumbh text-[#000000] text-base font-bold">
          Employee Awards
        </p>
      </div>

      <div className="mx-auto lg:mx-12 bg-white mt-5 min-h-screen p-4 sm:p-6">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-12">
          <div className="w-full lg:w-3/5">
            <h4 className="text-[#000000] font-medium mb-4">
              Outstanding Performance Appreciation
            </h4>
            <div className="flex flex-col gap-4">
              {cardData.map((card) => (
                <Card
                  key={card.type}
                  title={card.title}
                  description={card.description}
                  isActive={state.page === card.type}
                  onClick={() => dispatch({ type: card.type })}
                />
              ))}
            </div>
          </div>

          

          <div className="w-full lg:w-[675px] min-h-[823px]">
            <div className="border-2 border-[#F9FAFB] rounded-md p-4 sm:p-6 bg-white">
              {renderPage()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceAwards;
