import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";

const Awards = () => {
  return (
    <div className="bg-[#F5F7F8] min-h-screen">
      <Navbar />

      <PagesList />

      <div className="flex items-center gap-2 sm:gap-4 px-4 sm:px-6 lg:px-12 pt-4">
        <p className="font-Kumbh text-[#000000] text-sm sm:text-base font-bold">
          Employee Awards
        </p>
      </div>

      <div className="mx-auto lg:mx-12 bg-white mt-5 min-h-screen">
        <div className="py-4 px-4 sm:px-6 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <h4 className="text-[14px] sm:text-[16px] font-bold font-Satoshi">
            Overview
          </h4>

          <Link
            to={"performance-awards"}
            className="bg-[#5318FA] text-white text-sm sm:text-base px-3 sm:px-4 py-2 sm:py-3 rounded-md w-fit"
          >
            New Appreciation
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Awards;
