import React, { useState } from "react";
import AddContext from "../../assets/add_context.png";

const Other = () => {
  const [appreciation, setAppreciation] = useState("");
  const [selectedOption, setSelectedOption] = useState("notify");
  return (
    <div className="flex flex-col p-4">
      <div className="flex flex-col items-center">
        <img src={AddContext} alt="user_icon" />
        <p className="text-[#5608D4] font-Nunito font-[500] text-[20px] mt-4">
          Add Context (Optional)
        </p>
        <p className="text-[#7B7F9E] font-Nunito font-[400] text-[14px] mt-2">
          Share any additional details or a personal message
        </p>
      </div>

      <div className="flex flex-col items-center mt-8 flex-1">
        <div className="flex flex-col w-full mb-6">
          <label htmlFor="" className="text-[#3F3F3F] text-sm font-[500]">
            Message
          </label>
          <textarea className="border border-[#E1E8F1] text-[#8F8F8F] text-sm rounded-lg w-full p-2 mt-2 outline-none focus:ring-1 focus:ring-[#5608D4] transition-all h-20"></textarea>
        </div>
      </div>

      <div className="flex flex-col w-full mb-8">
        <label htmlFor="" className="text-[#3F3F3F] text-sm font-[500]">
          Choose who can see the appreciation
        </label>
        <select
          placeholder="Enter Recipient Name"
          className="border border-[#E1E8F1] text-[#8F8F8F] text-sm rounded-lg w-full p-2 mt-2 outline-none focus:ring-1 focus:ring-[#5608D4] transition-all"
          value={appreciation}
          onChange={(e) => setAppreciation(e.target.value)}
        >
          <option value="">IT & System</option>
          <option value="1">Department 1</option>
          <option value="2">Department 2</option>
          <option value="3">Department 3</option>
        </select>
      </div>

      <div className="flex flex-col w-full mb-6">
        <div className="flex flex-col gap-6">
          <label
            htmlFor="notify"
            className="flex items-center cursor-pointer gap-2"
          >
            <input
              type="radio"
              id="notify"
              name="notification"
              className="hidden"
              checked={selectedOption === "notify"}
              onChange={() => setSelectedOption("notify")}
            />
            <div
              className={`w-4 h-4 rounded-full border-2 flex items-center justify-center ${
                selectedOption === "notify"
                  ? "border-purple-600"
                  : "border-gray-400"
              }`}
            >
              {selectedOption === "notify" && (
                <div className="w-2 h-2 bg-purple-600 rounded-full"></div>
              )}
            </div>
            <span
              className={`${
                selectedOption === "notify" ? "text-black" : "text-gray-400"
              } font-medium`}
            >
              Send notification to the recipient
            </span>
          </label>

          <label
            htmlFor="comment"
            className="flex items-center cursor-pointer gap-2"
          >
            <input
              type="radio"
              id="comment"
              name="notification"
              className="hidden"
              checked={selectedOption === "comment"}
              onChange={() => setSelectedOption("comment")}
            />
            <div
              className={`w-4 h-4 rounded-full border-2 flex items-center justify-center ${
                selectedOption === "comment"
                  ? "border-purple-600"
                  : "border-gray-400"
              }`}
            >
              {selectedOption === "comment" && (
                <div className="w-2 h-2 bg-purple-600 rounded-full"></div>
              )}
            </div>
            <span
              className={`${
                selectedOption === "comment" ? "text-black" : "text-gray-400"
              } font-medium`}
            >
              Allow others to comment or add to the appreciation
            </span>
          </label>
        </div>

        <div className="flex justify-end mt-10">
          <button className="bg-[#5608D4] text-white text-sm sm:text-base px-4 py-2 rounded-md">
            Send Appreciation
          </button>
        </div>
      </div>
    </div>
  );
};

export default Other;
