import React, { useState } from "react";
import Envelope from "../../assets/envelope.png";

const Appreciation = () => {
  const [appreciation, setAppreciation] = useState("");
  const [achievement, setAchievement] = useState("");
  const [date, setDate] = useState("");
  const [attachments, setAttachments] = useState("");
  return (
    <div className="flex flex-col p-4">
      <div className="flex flex-col items-center">
        <img src={Envelope} alt="user_icon" />
        <p className="text-[#5608D4] font-Nunito font-[500] text-[20px] mt-4">
          Reason for Appreciation
        </p>
        <p className="text-[#7B7F9E] font-Nunito font-[400] text-[14px] mt-2">
          Tell us why your colleague deserves appreciation
        </p>
      </div>

      <div className="flex flex-col items-center mt-8 flex-1">
        <div className="flex flex-col w-full mb-6">
          <label htmlFor="" className="text-[#3F3F3F] text-sm font-[500]">
            Type of Appreciation
          </label>
          <select
            placeholder="Enter Recipient Name"
            className="border border-[#E1E8F1] text-[#8F8F8F] text-sm rounded-lg w-full p-2 mt-2 outline-none focus:ring-1 focus:ring-[#5608D4] transition-all"
            value={appreciation}
            onChange={(e) => setAppreciation(e.target.value)}
          >
            <option value="">IT & System</option>
            <option value="1">Department 1</option>
            <option value="2">Department 2</option>
            <option value="3">Department 3</option>
          </select>
        </div>

        <div className="flex flex-col w-full mb-6">
          <label htmlFor="" className="text-[#3F3F3F] text-sm font-[500]">
            Specific Achievement
          </label>
          <input
            type="text"
            placeholder="Describe the action"
            className="border border-[#E1E8F1] text-[#8F8F8F] text-sm rounded-lg w-full p-2 mt-2 outline-none focus:ring-1 focus:ring-[#5608D4] transition-all"
            value={achievement}
            onChange={(e) => setAchievement(e.target.value)}
          />
        </div>

        <div className="flex flex-col lg:flex-row gap-4 w-full mb-6">
          <div className="flex flex-col w-full">
            <label htmlFor="" className="text-[#3F3F3F] text-sm font-[500]">
              Date
            </label>
            <input
              type="date"
              placeholder="Enter ID"
              className="border border-[#E1E8F1] text-[#8F8F8F] text-sm rounded-lg w-full p-2 mt-2 outline-none focus:ring-1 focus:ring-[#5608D4] transition-all"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          <div className="flex flex-col w-full">
            <label htmlFor="" className="text-[#3F3F3F] text-sm font-[500]">
              Attachments ( Optional )
            </label>
            <input
              type="file"
              placeholder="Select Job Title"
              className="border border-[#E1E8F1] text-[#8F8F8F] text-sm rounded-lg w-full p-2 mt-2 outline-none focus:ring-1 focus:ring-[#5608D4] transition-all"
              value={attachments}
              onChange={(e) => setAttachments(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-10">
        <button className="bg-[#5608D4] text-white text-sm sm:text-base px-4 py-2 rounded-md">
          Next
        </button>
      </div>
    </div>
  );
};

export default Appreciation;
