import React from "react";

const Card = ({ title, description, onClick, isActive }) => {
  return (
    <div
      className={`card p-4 border rounded-lg shadow-sm cursor-pointer transition-all duration-200 mb-4 
        ${isActive ? " border-2 border-[#6B009D]" : "border-[#EEEEEE]"}`}
      onClick={onClick}
    >
      <h4
        className={`card-title font-bold mb-2 
          ${isActive ? "text-[#6B009D]" : "text-gray-800"}`}
      >
        {title}
      </h4>
      <p className="card-description text-gray-600">{description}</p>
    </div>
  );
};

export default Card;
